import React from 'react'
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"

const thanks = () => (

	 <Layout>
    <h1>Thank you!</h1>
    <p>This is a custom thank you page for form submissions</p>
  </Layout>

	)
export default thanks